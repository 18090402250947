import classnames from 'classnames'
import { parseISO, startOfDay } from 'date-fns'
import { LinkButton } from '../../common/components/Button'
import SimpleTable from '../../common/components/SimpleTable'
import { TextLink } from '../../common/components/Typography'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  BENEFIT_TYPE_SEASON_TICKET,
  EN_DASH,
} from '../../common/constants'
import { formatDate, formatMoney } from '../../common/format'
import PushTagEventOnView from '../../common/tagManager/PushTagEventOnView'
import { pushTagEvent } from '../../common/tagManager/tagManager'
import ROUTES from '../routes/routes'
import translateTicketType from './helpers/translateTicketType'

export const beneficiaryToFullName = (beneficiary, fallback = '') =>
  beneficiary && beneficiary.name
    ? `${beneficiary['name']['givenName']} ${beneficiary['name']['familyName']}`
    : fallback

export const formatValidityDates = (
  startDate,
  endDate,
  t,
  showValidForNowText = false
) => {
  // If the benefit is indefinitely valid
  if (!endDate) {
    return `${formatDate(startDate)}${EN_DASH}${
      showValidForNowText ? t('COMMON:FOR_NOW') : ''
    }`
  }

  return `${formatDate(startDate)}${EN_DASH}${formatDate(endDate)}`
}

export const formatBenefitValidityDates = (
  benefit,
  t,
  showValidForNowText = false
) => {
  return formatValidityDates(
    benefit.validFrom,
    benefit.validTo,
    t,
    showValidForNowText
  )
}

export const formatBenefitValidityStatus = (benefit, t) => {
  if (isUpcomingBenefit(benefit)) {
    return t('COMMON:BENEFIT_UPCOMING')
  }

  if (isActiveBenefit(benefit)) {
    return t('COMMON:BENEFIT_ACTIVE')
  }

  return t('COMMON:BENEFIT_INACTIVE')
}

export const isUpcomingBenefit = (benefit) => {
  if (!benefit?.validFrom) {
    return false
  }

  if (!benefit?.enabled) {
    return false
  }

  const startDate = parseISO(benefit?.validFrom)
  const now = new Date()

  return startOfDay(startDate) > startOfDay(now)
}

export const isActiveBenefit = (benefit) => {
  return Boolean(benefit?.enabled)
}

export const getAllowedBenefitTypes = (allowedBenefitTypes = {}) =>
  Object.keys(allowedBenefitTypes).filter((key) => allowedBenefitTypes[key])

export const getZoneLabelByProductId = (products, productId) => {
  const product = products.find((product) => product.productIdB === productId)

  return product ? product.validityArea : ''
}

export const translateBenefitType = (benefitType, t) => {
  if (benefitType === BENEFIT_TYPE_SEASON_TICKET) {
    return t('COMMON:COMMUTER_BENEFIT_SEASON_TICKET')
  }

  if (benefitType === BENEFIT_TYPE_ANNUAL_BALANCE) {
    return t('COMMON:COMMUTER_BENEFIT_ANNUAL_BALANCE')
  }

  if (benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE) {
    return t('COMMON:BUSINESS_TRIP_ANNUAL_BALANCE')
  }

  return t('COMMON:UNKNOWN_BENEFIT_TYPE_X', { benefitType })
}

export const checkIfTwoObjectsInArrHasSameValue = (
  array,
  propertyNameAsString,
  value,
  index
) =>
  array.some((item, i) => item[propertyNameAsString] === value && index !== i)

export const convertBeneficiariesToTableRows = (
  beneficiaries,
  company,
  singleBeneficiaryRoute,
  t,
  language
) =>
  beneficiaries.map((beneficiary) => {
    const allPurchases = [
      beneficiary.upcomingPurchase,
      ...beneficiary.latestPurchases,
    ].filter(Boolean)
    const [firstBenefit] = beneficiary.benefits
    const hasCompanyEnabledAnnualBalance = Boolean(
      company?.allowedBenefitTypes?.annualBalance
    )
    const benefitType =
      beneficiary?.benefits?.[0]?.benefitType ?? BENEFIT_TYPE_SEASON_TICKET
    const balance = beneficiary?.benefits?.[0]?.balance

    return {
      fields: {
        nameField: (
          <>
            <TextLink
              route={singleBeneficiaryRoute}
              onClick={() => pushTagEvent({ event: 'open_beneficiary' })}
              params={{
                companyId: company.id,
                beneficiaryId: beneficiary.id,
              }}
            >
              {[beneficiary.name.familyName, beneficiary.name.givenName].join(
                ', '
              )}
            </TextLink>
            <p className="beneficiary-list-employee-id">
              {beneficiary.employeeId}
            </p>
          </>
        ),
        invoiceField: beneficiary.invoice
          ? beneficiary.invoice.name
          : undefined,
        tagField: firstBenefit.tag,
        benefitTypeField: translateBenefitType(benefitType, t),
        validityField: (
          <>
            <div
              className={classnames(
                'company__beneficiaries-screen__validity-status',
                isUpcomingBenefit(firstBenefit)
                  ? 'company__beneficiaries-screen__validity-status--upcoming'
                  : isActiveBenefit(firstBenefit)
                    ? 'company__beneficiaries-screen__validity-status--active'
                    : 'company__beneficiaries-screen__validity-status--ended'
              )}
            >
              {formatBenefitValidityStatus(firstBenefit, t)}
            </div>
            <div className="company__beneficiaries-screen__validity-dates">
              {formatBenefitValidityDates(firstBenefit, t, true)}
            </div>
          </>
        ),
        ...(hasCompanyEnabledAnnualBalance
          ? {
              annualBalanceField: balance
                ? formatMoney(balance.remaining, language)
                : EN_DASH,
              grantedBalanceField:
                balance?.monthlyBalance?.monthlyGranted || balance?.granted
                  ? formatMoney(
                      balance.monthlyBalance?.monthlyGranted || balance.granted,
                      language
                    )
                  : EN_DASH,
            }
          : {}),
        zone: firstBenefit?.zone,
        ...beneficiary,
      },
      expandableBody: (
        <>
          <PushTagEventOnView event={{ event: 'expand_beneficiary' }} />
          <SimpleTable
            fields={[
              { fieldName: 'purchaseDate', label: t('COMMON:PURCHASE_DATE') },
              {
                fieldName: 'benefitType',
                label: t('COMPANY:BENEFICIARIES_SCREEN.BENEFIT_TYPE'),
              },
              { fieldName: 'ticketType', label: t('COMMON:TICKET') },
              { fieldName: 'zone', label: t('COMMON:ZONES') },
              { fieldName: 'validity', label: t('COMMON:TICKET_VALIDITY') },
              { fieldName: 'price', label: t('COMMON:SUM') },
            ]}
            rows={allPurchases.map((purchase) => ({
              fields: {
                purchaseDate:
                  purchase.state === 'PROJECTED' ? (
                    <>
                      <div className="company__single-beneficiary-screen__next-purchase">
                        {t('COMPANY:SINGLE_BENEFICIARY_SCREEN.NEXT_PURCHASE')}
                      </div>
                      {formatDate(purchase.time)}
                    </>
                  ) : (
                    formatDate(purchase.time)
                  ),
                benefitType: translateBenefitType(purchase.benefitType, t),
                ticketType: translateTicketType(purchase, t),
                zone: purchase.validityArea,
                validity: formatValidityDates(
                  purchase.validFrom,
                  purchase.validTo,
                  t,
                  true
                ),
                price: formatMoney(purchase.price, language),
              },
              className:
                purchase.state === 'PROJECTED'
                  ? 'company__beneficiaries-screen__table-row-label'
                  : '',
              style:
                purchase.state === 'PROJECTED'
                  ? { backgroundColor: '#f2f5f7' }
                  : { backgroundColor: 'transparent' },
            }))}
            gridTemplateColumnsStyle="170px 200px auto 140px 220px 110px"
          />
        </>
      ),
      append: (
        <div className="company__beneficiaries-screen__appended-row">
          <LinkButton
            route={ROUTES.COMPANY_ADMIN.BENEFICIARY}
            params={{ companyId: company.id, beneficiaryId: beneficiary.id }}
            secondary
            small
          >
            {t('COMPANY:BENEFICIARIES_SCREEN.SHOW_ALL_DETAILS')}
          </LinkButton>
        </div>
      ),
    }
  })

const toNumber = (value) => {
  return window.isNaN(value) ? undefined : Number(value)
}

export const convertQueryParamsToFilters = (queryParams) => {
  const filters = {
    searchText: queryParams.searchText ?? '',
    isArchived: queryParams.isArchived,
  }

  if (
    queryParams.benefitType === BENEFIT_TYPE_ANNUAL_BALANCE ||
    queryParams.benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE
  ) {
    if (typeof queryParams.annualBalanceMin !== 'undefined') {
      filters.annualBalanceMin = toNumber(queryParams.annualBalanceMin) ?? ''
    }

    if (typeof queryParams.annualBalanceMax !== 'undefined') {
      filters.annualBalanceMax = toNumber(queryParams.annualBalanceMax) ?? ''
    }
  } else {
    if (queryParams.zone) {
      filters.zone = queryParams.zone
    }
  }

  if (queryParams.benefitType) {
    filters.benefitType = queryParams.benefitType
  }

  if (queryParams.validity) {
    filters.validity = queryParams.validity
  }

  if (queryParams.invoiceId) {
    filters.invoiceId = queryParams.invoiceId
  }

  if (queryParams.calendarId) {
    filters.calendarId = queryParams.calendarId
  }

  return filters
}

export const isDateDecember = (date) => {
  const zeroBasedMonth = date.getMonth()

  return zeroBasedMonth === 11
}

export const updateArrayElementInIndex = (array, updatedElement, index) => {
  const updatedArray = [...array]
  updatedArray[index] = updatedElement

  return updatedArray
}

export const checkObjectForTrue = (object) => {
  return Object.values(object).some((val) => val === true)
}

export const checkIfObjectValuesByPropertiesMatch = (
  object1,
  object2,
  properties
) => {
  return properties.every((s) => {
    return object1[s] === object2[s]
  })
}
