import { observer } from 'mobx-react'
import { USER_ROLES } from '../../../common/constants'
import userStore from '../../../common/stores/userStore'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'

const LogoutSuccess = () => {
  return (
    <Redirect
      route={
        userStore.lastUserRole === USER_ROLES.HSL_ADMIN
          ? ROUTES.SUPERVISOR.LANDING
          : ROUTES.ROOT
      }
    />
  )
}

export default observer(LogoutSuccess)
