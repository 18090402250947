import classnames from 'classnames'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { useImmutable } from '../utility'
import InputError from './InputError'
import InputLabel from './InputLabel'
import './TextInput.scss'

const TextInput = forwardRef(
  (
    {
      type = 'text',
      label,
      labelSuffix,
      value,
      error,
      hasError = typeof error === 'string',
      required,
      placeholder,
      id,
      prepend,
      prependAlt,
      small,
      max,
      className,
      style,
      inputClassName,
      inputStyle,
      labelStyle,
      labelClassName,
      ariaLabel,
      disabled,
      optional,
      fitContent,
      inputUnit,
      ...props
    },
    ref
  ) => {
    const formContext = useFormContext()
    const generatedId = useImmutable(`TextInput__${uuid()}`)
    const inputId = id || generatedId
    const [t] = useTranslation()
    const charactersRemaining = max
      ? max - (value?.length ?? formContext?.watch(props.name)?.length ?? 0)
      : undefined

    return (
      <div
        className={classnames('common__text-input', className)}
        style={style}
      >
        {label && (
          <InputLabel
            required={required}
            htmlFor={inputId}
            className={labelClassName}
            style={labelStyle}
            optional={optional}
            labelSuffix={labelSuffix}
          >
            {label}
          </InputLabel>
        )}
        <div className="common__text-input__container">
          {Boolean(prepend) && (
            <div className="common__text-input__prepend">
              <img
                src={prepend}
                className="common__text-input__prepend-image"
                alt={prependAlt}
              />
            </div>
          )}
          <div className="flex items-center">
            <input
              ref={ref}
              id={inputId}
              type={type}
              value={value}
              className={classnames(
                'common__text-input__input',
                {
                  'common__text-input__input--error':
                    hasError || charactersRemaining < 0,
                  'common__text-input__input--small': small,
                  'common__text-input__input--has-prepend': Boolean(prepend),
                  'common__text-input__input--disabled': disabled,
                  'common__text-input__input--fit-content': fitContent,
                },
                inputClassName
              )}
              style={inputStyle}
              required={required}
              placeholder={placeholder}
              aria-label={ariaLabel || label || placeholder}
              aria-required={required}
              disabled={disabled}
              {...props}
            />
            {inputUnit && <span className="ml-xs">{inputUnit}</span>}
          </div>
          {max !== undefined &&
            !error &&
            (charactersRemaining < 0 ? (
              <InputError>
                {t('COMMON:MAX_CHARACTER_LENGTH_OF_FIELD', { max })}
              </InputError>
            ) : (
              <div className="mt-xxs text-dark-gray">
                {t('COMMON:X_CHARACTERS_REMAINING', {
                  remaining: charactersRemaining,
                })}
              </div>
            ))}
        </div>
        <InputError>{error}</InputError>
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  labelSuffix: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  prepend: PropTypes.string,
  prependAlt: PropTypes.string,
  small: PropTypes.bool,
  max: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  inputClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  labelClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  fitContent: PropTypes.bool,
  inputUnit: PropTypes.string,
  name: PropTypes.string,
}

export default TextInput
